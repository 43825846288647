<script setup lang="ts">
import { type FormWidgetData } from "../../../widgetDataTs.js";
import { useFormServices } from "../../composables/use-form-services.js";
import { computed } from 'vue';
import resourceForm from "../form-element/resource-form-base.vue";
import { GetComputedProperties } from "../../composables/use-custom-data-values.js";
import { isNil } from "@mcwd/typescript-type-guards";

const props = defineProps<{
  formSettingsKey: "ppc-video"
  widgetData: FormWidgetData<"GatedVideoForm">;
  customData?: Record<string, any>
}>();
const video = computed(() => {
  return props.widgetData.setup.form.video.selected;
});
const {ctaButtonText, formHeading, CustomPostMessageHeader, CustomPostMessageBody} =  GetComputedProperties(props.customData);

const { FormState } = useFormServices({ callerLabel: "ppcVideoForm" });
const showUnlockedView = computed(() => {
  return FormState.value === "RegularFormComplete";
});
</script>
<template>
  <resourceForm v-if="!showUnlockedView" :form-settings-key="formSettingsKey" :widget-data="widgetData"
    :custom-data="customData">
    <template v-if="!isNil(formHeading) && formHeading != ''" #heading>
      <h2 class="form-instructions-heading">
        {{ formHeading }}
      </h2>
      <br>
    </template>
  </resourceForm>
  <template v-else>
    <div class="success-region after-complete">
      <div class="form-complete d-flex flex-column justify-content-between">
        <div class="response">
          <h3>{{CustomPostMessageHeader ? CustomPostMessageHeader : "Thank you for completing the form."}}</h3>
          <p>{{ CustomPostMessageBody ? CustomPostMessageBody : "Click the button below to watch the video." }}</p>
          <a class="btn btn-primary stroked js-cta" href="#mcui-modal" data-modal-type="youtube" data-bs-toggle="modal"
            :data-docid="video.youTubeId" data-sf-ec-immutable="">
            <span>{{ ctaButtonText ?? "Watch the Video"}}</span>
          </a>
        </div>
      </div>
    </div>
  </template>
</template>